import React from "react"
import PropType from "prop-types"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { CssGrid } from "../components/CssGrid"
import { Hero } from "../components/Hero"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { Content } from "../components/Content"
import { Subnavigation } from "../components/Subnavigation"
import { graphql } from "gatsby"

let SUBMENU = []

const PostTemplate = props => {
  const {
    data: { wordpressWpVideos: post, submenu },
  } = props
  return (
    <Layout>
      <Helmet
        title={post.title}
        meta={[{ name: "description", content: post.excerpt }]}
      />
      <CssGrid className="internal-page">
        <article>
          <Hero>
            <Content
              fillBackgroundWithImage
              fillBlockWithImage
              backgroundImage="about_ovolo_banner.jpg"
            >
              <h1>Video Gallery</h1>
            </Content>
          </Hero>
          {submenu.edges.map(({ node }, i) => {
            SUBMENU = node.items
          })}
          <Subnavigation items={SUBMENU} />
          <Breadcrumbs categoryName="Videos" />
          <div className="container">
            <div className="row">
              <CssGrid>
                <div className="col col-xs-12 col-md-9">
                  <Content>
                    <h2>{post.title}</h2>
                    <div className="date">{post.date}</div>
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                  </Content>
                </div>
              </CssGrid>
            </div>
          </div>
        </article>
      </CssGrid>
    </Layout>
  )
}
PostTemplate.propTypes = {
  data: PropType.shape({}).isRequired,
}
export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpVideos(id: { eq: $id }) {
      title
      date(formatString: "DD, MMM YYYY")
      slug
    }
    submenu: allWordpressWpApiMenusMenusItems(
      filter: { slug: { eq: "media" } }
    ) {
      edges {
        node {
          items {
            title
            url
            target
          }
        }
      }
    }
  }
`
